import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import "../Return/Return.css";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import { getInventoryLogList } from "../../Redux/actions/utilitiesAction";

const InventoryLogs = () => {
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(false);
  const [extraQuantity, setExtraQuantity] = useState(0);
  const [shortageQuantity, setShortageQuantity] = useState(0);
  const [searchId, setSearchId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const { supplierList } = useSelector((state) => state.supplierList);
  const { loading, inventoryLogList, error } = useSelector(
    (state) => state.inventoryLogList
  );

  useEffect(() => {
    if (!supplierList.data) {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList?.data]);

  useEffect(() => {
    if (
      showResults &&
      inventoryLogList.products_data &&
      inventoryLogList.products_data.length > 0
    ) {
      inventoryLogList.products_data.map((prod) => {
        if (prod.wh_mismatch > 0) {
          setExtraQuantity((prev) => prev + prod.wh_mismatch);
        }
        if (prod.wh_mismatch < 0) {
          setShortageQuantity((prev) => prev + Math.abs(prod.wh_mismatch));
        }
        if (prod.retail_mismatch > 0) {
          setExtraQuantity((prev) => prev + prod.retail_mismatch);
        }
        if (prod.retail_mismatch < 0) {
          setShortageQuantity((prev) => prev + Math.abs(prod.retail_mismatch));
        }
      });
    }
  }, [showResults, inventoryLogList]);

  const generateInventoryLogList = () => {
    setExtraQuantity(0);
    setShortageQuantity(0);
    if (searchId.length && supplierId.length) {
      setShowResults(true);
      dispatch(
        getInventoryLogList(`?supplier_id=${supplierId}&log_id=${searchId}`)
      );
    } else if (searchId.length && !supplierId.length) {
      setShowResults(true);
      dispatch(getInventoryLogList(`?log_id=${searchId}`));
    } else if (!searchId.length && supplierId.length) {
      setShowResults(true);
      dispatch(getInventoryLogList(`?supplier_id=${supplierId}`));
    } else {
      alert("Insert Physcial Inventory ID or Supplier");
    }
  };
  //console.log(inventoryLogList);
  return (
    <>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Inventory Logs</h4>
          <p className="product-paragraph container-view">
            {" "}
            Utilities <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
            Inventory Logs
          </p>
          <div
            className="card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={3}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Supplier Name
                    <span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setSupplierId(e.target.value)}
                    value={supplierId}
                    // required
                  >
                    <option value="">Select Supplier</option>
                    {supplierList.data?.results.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.supplier_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Supplier Id<span></span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Supplier Id"
                    value={supplierId}
                    disabled
                    // value={
                    //   selectProduct.supplier_id !== null &&
                    //   selectProduct.supplier_id !== undefined
                    //     ? selectProduct.supplier_id
                    //     : ""
                    // }
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Button
                  className="report-btn border-0"
                  onClick={generateInventoryLogList}
                >
                  Generate Report
                </Button>
              </Col>
              <Col lg={3}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search By Physical Inventory ID"
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {showResults ? (
              <>
                <div className="mt-3">
                  {loading ? (
                    <Row className="mt-2">
                      <Col lg={12} className="d-flex justify-content-center">
                        <Spinner animation="border" variant="warning" />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Table
                        responsive
                        bordered
                        hover
                        style={{ fontSize: "14px" }}
                        //onKeyDown={(e) => searchInputSuggestionHendler(e)}
                      >
                        <thead className="table-header">
                          <tr>
                            <th>Supplier</th>
                            <th>Barcode</th>
                            <th>Product Information</th>
                            <th>Scan Date</th>
                            <th>Scan Time</th>
                            <th>Previous Qty (Warehouse)</th>
                            <th>Mismatch Qty (Warehouse)</th>
                            <th>Previous Qty (Outlet)</th>
                            <th>Mismatch Qty (Outlet)</th>
                            <th>Total CPU</th>
                            <th>Scanned By</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {inventoryLogList.products_data &&
                          inventoryLogList.products_data.length > 0 ? (
                            <>
                              {inventoryLogList.products_data.map(
                                (product, i) => (
                                  <tr key={product.id}>
                                    <td>{product.supplier_name}</td>
                                    <td>
                                      {product.barcode
                                        ? product.barcode
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.product_name}{" "}
                                      {product.generic_name}{" "}
                                      {product.product_unit}
                                    </td>
                                    <td>{product.scanned_date}</td>
                                    <td>{product.scanned_time.slice(0, 8)}</td>
                                    <td>{product.wh_stock}</td>
                                    <td>{product.wh_mismatch}</td>
                                    <td>{product.retail_stock}</td>
                                    <td>{product.retail_mismatch}</td>
                                    <td>{product.total_cpu}</td>
                                    <td>{product.scan_by}</td>
                                  </tr>
                                )
                              )}
                              <tr style={{ fontWeight: "600" }}>
                                <td colSpan={5}>Grand Total</td>
                                <td>
                                  {inventoryLogList.grand_totals.total_wh}
                                </td>
                                <td>
                                  {inventoryLogList.grand_totals.total_wh_scan}
                                </td>
                                <td>
                                  {inventoryLogList.grand_totals.total_retail}
                                </td>
                                <td>
                                  {
                                    inventoryLogList.grand_totals
                                      .total_retail_scan
                                  }
                                </td>
                                <td>
                                  {inventoryLogList.grand_totals.grand_cpu}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          ) : (
                            <tr className="text-center">
                              <td colSpan="11">No Product Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="p-2">
                        <Row className="">
                          <Col
                            className="d-flex justify-content-end"
                            style={{ height: "30px" }}
                          >
                            <p>
                              <b>Extra Qty: </b> {extraQuantity}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-end"
                            style={{ height: "30px" }}
                          >
                            <p>
                              <b>Shortage Qty: </b> {shortageQuantity}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Select A Supplier To Show Inventory Logs
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
};

export default InventoryLogs;
