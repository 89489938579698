import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "./Return.css";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import {
  getReturnList,
  searchReturnList,
} from "../../Redux/actions/returnActions";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import SingleReturnPdf from "../../Services/SingleReturnPdf";

const ReturnList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedReturn, setSelectedReturn] = useState("");
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const { loading, returnList, error } = useSelector(
    (state) => state.returnList
  );

  const { searchLoading, searchReturn, searchError } = useSelector(
    (state) => state.searchReturnList
  );

  useEffect(() => {
    dispatch(getReturnList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (returnList?.data) {
      setTotalItems(returnList.data.count);
    }
  }, [returnList]);
  //console.log(purchaseList);

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(searchReturnList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    setCheckedItems([
      ...checkedItems,
      returnList?.data.results.find((item) => item.uuid === id),
    ]);
    //setSelectedItems(array);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setCheckedItems(checkedItems.filter((item) => item.uuid !== id));
      setIsCheckAll(false);
    }
    //console.log(typeof parseInt(id));
    //console.log(checked);
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setCheckedItems(returnList?.data.results);
    setIsCheck(returnList?.data.results.map((li) => li.uuid));
    if (isCheckAll) {
      setIsCheck([]);
      setCheckedItems([]);
    }
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/ReturnProductListView/?limit=${totalItems}&offset=0&ordering=id`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Return",
              [
                "Return ID",
                "Vendor Name",
                "Return Date",
                "Total Return Value",
                "Net Return Value",
                "Returned By",
              ],
              "Care-Box Return List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Return ID"] = each.uuid;
              obj["Vendor Name"] = each.vendor_name;
              obj["Return Date"] = each.created_at.split("T")[0];
              obj["Total Return Value"] = each.totalreturnvalue;
              obj["Net Return Value"] = each.netreturnvalue;
              obj["Returned By"] = each.createdby_name;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Return_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };

  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => {
          setDeleteModalShow(false);
          setSelectedReturn("");
          setSelectedItem("");
        }}
        returnProduct={selectedItem}
        return={selectedReturn}
        action={() => setShowSearchResult(false)}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Return List</h4>
              <p className="product-paragraph">
                {" "}
                Return <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Return List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addReturn")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.4rem" }}
                />
                Add Return
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      checkedItems.length === 1
                        ? SingleReturnPdf(checkedItems[0])
                        : checkedItems.length > 1
                        ? generatePDF(
                            checkedItems,
                            "Return",
                            [
                              "Return ID",
                              "Vendor Name",
                              "Return Date",
                              "Total Return Value",
                              "Net Return Value",
                              "Returned By",
                            ],
                            "Care-Box Return List (Selected)",
                            checkedItems.length
                          )
                        : getDataFromApi("pdf");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive="xxl" hover>
              <thead className="text-center table-header">
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  </th>
                  <th>Return Id</th>
                  <th>Vendor Name</th>
                  <th>Total Return Value</th>
                  <th>Net Return Value</th>
                  <th>Return Date</th>
                  <th>Returned By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchReturn?.data !== undefined &&
                searchReturn?.data.results.length !== 0 ? (
                  searchReturn?.data.results.map((list) => (
                    <Fragment key={list.uuid}>
                      <tr
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <td>
                          <Form.Check
                            key={list.uuid}
                            type="checkbox"
                            id={list.uuid}
                            onChange={handleClick}
                            checked={isCheck.includes(list.uuid)}
                          />
                        </td>
                        <td>{list.uuid}</td>
                        <td>{list.vendor_name}</td>
                        <td>{list.totalreturnvalue}</td>
                        <td>{list.netreturnvalue}</td>
                        <td>{list.returndate}</td>
                        <td>{list.createdby_name}</td>
                        <td style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faEye}
                            color="#637381"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                            onClick={() =>
                              setSelected(
                                selected !== list.uuid ? list.uuid : null
                              )
                            }
                          />
                          {/* <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.4rem" }}
                            onClick={() => {
                              setDeleteModalShow(true);
                              setSelectedReturn(list);
                            }}
                          /> */}
                        </td>
                      </tr>
                      <tr
                        id={
                          selected === list.uuid
                            ? "flush-collapseOne"
                            : "collapse"
                        }
                        className={
                          selected === list.uuid
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <td colSpan={12}>
                          <Table className="mt-2 inside-table" responsive>
                            <thead className="text-center">
                              <tr>
                                <th>Id</th>
                                <th>Product Name</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>P. Price</th>
                                <th>Vat %</th>
                                <th>Batch No.</th>
                                <th>SubTotal</th>
                                <th>Barcode</th>
                                <th>S. Name</th>
                                <th>Reasons</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {list.returnitem.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.product__product_name}</td>
                                  <td>{item.unit}</td>
                                  <td>{item.quantity}</td>0
                                  <td>{item.purchase_price}</td>
                                  <td>{item.vat}</td>
                                  <td>{item.batch_no}</td>
                                  <td>{item.subtotal}</td>
                                  <td>{item.barcode}</td>
                                  <td>{item.supplier_name}</td>
                                  <td>{item.reasons}</td>
                                  <td
                                    className="d-none"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      color="#E40000"
                                      style={{ paddingLeft: "0.4rem" }}
                                      onClick={() => {
                                        setDeleteModalShow(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </Fragment>
                  ))
                ) : !showSearchResult &&
                  returnList?.data !== undefined &&
                  returnList?.data.results.length !== 0 ? (
                  returnList?.data.results.map((list) => (
                    <Fragment key={list.uuid}>
                      <tr
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <td>
                          <Form.Check
                            key={list.uuid}
                            type="checkbox"
                            id={list.uuid}
                            onChange={handleClick}
                            checked={isCheck.includes(list.uuid)}
                          />
                        </td>
                        <td>{list.uuid}</td>
                        <td>{list.vendor_name}</td>
                        <td>{list.totalreturnvalue}</td>
                        <td>{list.netreturnvalue}</td>
                        <td>{list.created_at.split("T")[0]}</td>
                        <td>{list.createdby_name}</td>
                        <td style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faEye}
                            color="#637381"
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                            onClick={() =>
                              setSelected(
                                selected !== list.uuid ? list.uuid : null
                              )
                            }
                          />
                          {/* <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.4rem" }}
                            onClick={() => {
                              setDeleteModalShow(true);
                              setSelectedReturn(list);
                            }}
                          /> */}
                        </td>
                      </tr>
                      <tr
                        id={
                          selected === list.uuid
                            ? "flush-collapseOne"
                            : "collapse"
                        }
                        className={
                          selected === list.uuid
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <td colSpan={12}>
                          <Table className="mt-2 inside-table" responsive>
                            <thead className="text-center">
                              <tr>
                                <th>Id</th>
                                <th>Product Name</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>P. Price</th>
                                <th>Vat %</th>
                                <th>Batch No.</th>
                                <th>SubTotal</th>
                                <th>Barcode</th>
                                <th>S. Name</th>
                                <th>Reasons</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {list.returnitem.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.product__product_name}</td>
                                  <td>{item.product__unit}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.purchase_price}</td>
                                  <td>{item.vat ? item.vat + "%" : "N/A"}</td>
                                  <td>{item.batch_no}</td>
                                  <td>{item.subtotal}</td>
                                  <td>{item.product__barcode}</td>
                                  <td>
                                    {item.supplier__supplier_name
                                      ? item.supplier__supplier_name
                                      : "N/A"}
                                  </td>
                                  <td>{item.reasons}</td>
                                  <td
                                    className="d-none"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      color="#E40000"
                                      style={{ paddingLeft: "0.4rem" }}
                                      onClick={() => {
                                        setDeleteModalShow(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </Fragment>
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ReturnList;
