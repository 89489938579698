import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import VendorForm from "./VendorForm";
import "../Products/Product.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import {
  getVendorDetails,
  updateVendor,
} from "../../Redux/actions/vendorActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const UpdateVendor = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { vendor, error } = useSelector((state) => state.vendorDetail);
  const { loading, message, updateError } = useSelector(
    (state) => state.updateVendor
  );
  const [vendorProductCategory, setVendorProductCategory] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [vendorDocument, setVendorDocument] = useState(null);
  const [status, setStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    dispatch(getVendorDetails(param.id));
  }, [dispatch]);

  useEffect(() => {
    if (vendor && vendor.id !== undefined) {
      setVendorProductCategory(vendor.category_of_vendor);
      setVendorName(vendor.vendor_name);
      setVendorAddress(vendor.vendor_address);
      setContactNumber(vendor.contact_number);
      setEmailAddress(vendor.email_address);
      setContactPerson(vendor.contact_Person);
      setVendorDocument(vendor.Documents);
      setStatus(vendor.status);
    }
  }, [vendor]);

  useEffect(() => {
    if (alertMessage && message === undefined && updateError !== undefined) {
      //console.log(updateError);
      if (updateError.error) {
        alert(updateError.error);
        setAlertMessage(false);
      } else {
        alert("Something Went Wrong");
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("Vendor Updated Successfully");
      setAlertMessage(false);
      dispatch(getVendorDetails(param.id));
    }
  }, [alertMessage, message, updateError]);

  const handleSubmitForm = (e) => {
    if (typeof vendorDocument === "string" || vendorDocument === null) {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("category_of_vendor", vendorProductCategory);
      form_data.append("vendor_name", vendorName);
      form_data.append("vendor_address", vendorAddress);
      form_data.append("contact_number", contactNumber);
      form_data.append("email_address", emailAddress);
      form_data.append("contact_Person", contactPerson);
      form_data.append("status", status);
      //console.log(form_data);
      dispatch(updateVendor(vendor.id, form_data));
      setAlertMessage(true);
    } else {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("category_of_vendor", vendorProductCategory);
      form_data.append("vendor_name", vendorName);
      form_data.append("vendor_address", vendorAddress);
      form_data.append("contact_number", contactNumber);
      form_data.append("email_address", emailAddress);
      form_data.append("contact_Person", contactPerson);
      form_data.append("status", status);
      form_data.append("Documents", vendorDocument);
      dispatch(updateVendor(vendor.id, form_data));
      setAlertMessage(true);
    }
  };
  return (
    <>
      {error && error.includes("Not found.") ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header></Header>
            <ToastContainer />
            <Container fluid>
              <h4 className="product-heading container-view">
                Vendor Details Update
              </h4>
              <p className="product-paragraph container-view">
                Vendor <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Vendor List <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Update Vendor Details ({vendor.id})
              </p>
              <div className="mb-4 card product-card container-view">
                <VendorForm
                  loading={loading}
                  vendorId={vendor.id}
                  vendorProductCategory={vendorProductCategory}
                  setVendorProductCategory={setVendorProductCategory}
                  vendorName={vendorName}
                  setVendorName={setVendorName}
                  vendorAddress={vendorAddress}
                  setVendorAddress={setVendorAddress}
                  contactNumber={contactNumber}
                  setContactNumber={setContactNumber}
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  contactPerson={contactPerson}
                  setContactPerson={setContactPerson}
                  vendorDocument={vendorDocument}
                  setVendorDocument={setVendorDocument}
                  status={status}
                  setStatus={setStatus}
                  handleSubmitForm={handleSubmitForm}
                />
              </div>
            </Container>
          </div>
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default UpdateVendor;
