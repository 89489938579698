import React from "react";
import { Button, Col, Spinner, Form, Row } from "react-bootstrap";
import uploadIcon from "../../Assets//icons/upload-image.png";

const VendorForm = (props) => {
  const handleFileUpload = (e) => {
    props.setVendorDocument(e.target.files[0]);
  };
  const handleCancelClick = () => {
    window.location.reload();
  };
  return (
    <Form className="p-4" onSubmit={props.handleSubmitForm}>
      <Row>
        <Col lg={8}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Category of Vendor<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.vendorProductCategory}
              onChange={(e) => props.setVendorProductCategory(e.target.value)}
              required
            >
              <option value="">
                aftersales / bill to bill / Cash / credit period / fresh food /
                other
              </option>
              <option value="Aftersale">aftersales</option>
              <option value="Bill_to_bill">bill to bill</option>
              <option value="Cash">Cash</option>
              <option value="CreditPeriod">credit period</option>\
              <option value="FreshFood">fresh food</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Vendor Id<span></span>{" "}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Auto Generate"
              value={props.vendorId !== undefined ? props.vendorId : ""}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Vendor Name<span className="required-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Vendor Name"
              value={props.vendorName}
              onChange={(e) => props.setVendorName(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Vendor Address<span className="required-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Vendor Address"
              value={props.vendorAddress}
              onChange={(e) => props.setVendorAddress(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Contact Number<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Number"
              maxLength={14}
              value={props.contactNumber}
              onChange={(e) => props.setContactNumber(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Email Address<span className="required-field"></span>{" "}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              value={props.emailAddress}
              onChange={(e) => props.setEmailAddress(e.target.value)}
              //required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Contact Person<span className="required-field">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Person Name"
              value={props.contactPerson}
              onChange={(e) => props.setContactPerson(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Documents</Form.Label>
          <br />
          <label
            className="pt-4 pb-4"
            htmlFor="inputTag"
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              border: "1px solid #DCE0E4",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={uploadIcon} alt="uploadIcon" height={30} width={30} />
              <span className="image-upload-text">Upload Documents</span>
              {props.vendorDocument !== null &&
              typeof props.vendorDocument !== "string" ? (
                <p>
                  <b>Chosen file:</b> {props.vendorDocument.name}
                </p>
              ) : props.vendorDocument !== null &&
                typeof props.vendorDocument === "string" ? (
                <p>
                  <b>Uploaded file:</b> {props.vendorDocument}
                </p>
              ) : (
                <p>
                  <b>Chosen file:</b> No file chosen yet
                </p>
              )}
            </div>
            <input
              id="inputTag"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </label>
        </Form.Group>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Status<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setStatus(e.target.value)}
              value={props.status}
              required
            >
              <option value="">Active or In Active</option>
              <option value="Active">Active</option>
              <option value="Inactive">In Active</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {props.loading === true ? (
        <Row className="mt-2">
          <Col xl={4} lg={12} className="d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col xl={2} lg={6} className="mb-2">
          {props.loading === true ? (
            <Button className="disable-submit-btn" variant="secondary" disabled>
              Submit
            </Button>
          ) : (
            <Button className="submit-btn border-0" type="submit">
              Submit
            </Button>
          )}
        </Col>
        <Col xl={2} lg={6}>
          <Button className="cancel-btn border-0" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default VendorForm;
