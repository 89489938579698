import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/finalLogo.png";
import dashboardLogo from "../../Assets/icons/Dashboard.png";
import productLogo from "../../Assets/icons/Box.png";
import supplierLogo from "../../Assets/icons/arcticons_company-portal.png";
import vendorLogo from "../../Assets/icons/arcticons_vendora.png";
import purchaseLogo from "../../Assets/icons/arcticons_purchased-apps.png";
import returnLogo from "../../Assets/icons/Return Delivery Box.png";
import warehouseLogo from "../../Assets/icons/warehouse.png";
import transferLogo from "../../Assets/icons/transfer.png";
import reportsLogo from "../../Assets/icons/mdi_report-finance.png";
import listLogo from "../../Assets/icons/Ellipse.png";
import selectedLogo from "../../Assets/icons/selected.png";
import posLogo from "../../Assets/icons/cashier.png";
import dueLogo from "../../Assets/icons/due.png";
import utilitiesLogo from "../../Assets/icons/utilities.png";
import voidLogo from "../../Assets/icons/void.png";
import "./Sidebar.css";
import { SidebarAction } from "../../Redux/actions/sidebarActions";

const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState();
  const { toggleClick } = useSelector((state) => state.sideBarState);
  const { user } = useSelector((state) => state.userDetails);
  const [voidUser, setVoidUser] = useState(false);
  //console.log(toggleClick);
  useEffect(() => {
    if (
      location.pathname === "/productList" ||
      location.pathname === "/addProducts" ||
      location.pathname === "/category" ||
      location.pathname === "/subCategory" ||
      location.pathname === "/genericList" ||
      location.pathname.includes("/product/") ||
      location.pathname.includes("/updateProduct/")
    ) {
      setActiveState("second");
    } else if (
      location.pathname === "/supplierList" ||
      location.pathname === "/addSupplier" ||
      location.pathname.includes("/supplier/") ||
      location.pathname.includes("/updateSupplier/")
    ) {
      setActiveState("third");
    } else if (
      location.pathname === "/vendorList" ||
      location.pathname === "/addVendor" ||
      location.pathname.includes("/vendor/") ||
      location.pathname.includes("/updateVendor/")
    ) {
      setActiveState("fourth");
    } else if (
      location.pathname === "/purchaseList" ||
      location.pathname === "/addPurchase" ||
      location.pathname === "/requestPurchase" ||
      location.pathname === "/addRequestPurchase" ||
      location.pathname === "/purchaseOrderList" ||
      location.pathname === "/addPurchaseOrder" ||
      location.pathname.includes("/purchase/") ||
      location.pathname.includes("/purchaseOrder/")
    ) {
      setActiveState("fifth");
    } else if (
      location.pathname === "/returnList" ||
      location.pathname === "/addReturn"
    ) {
      setActiveState("sixth");
    } else if (
      location.pathname === "/stockReports" ||
      location.pathname === "/stockAnalysis" ||
      location.pathname === "/returnReports" ||
      location.pathname === "/supplierRcv" ||
      location.pathname === "/vendorRcv"
    ) {
      setActiveState("seventh");
    } else if (
      location.pathname === "/destinationList" ||
      location.pathname === "/addDestination" ||
      location.pathname.includes("/destination/") ||
      location.pathname.includes("/updateDestination/")
    ) {
      setActiveState("ninth");
    } else if (
      location.pathname === "/transferList" ||
      location.pathname === "/addTransfer" ||
      location.pathname.includes("/transfer/")
    ) {
      setActiveState("tenth");
    } else if (location.pathname === "/due-details") {
      setActiveState("eleventh");
    } else if (
      location.pathname === "/productBiography" ||
      location.pathname === "/createInventory" ||
      location.pathname === "/physicalInventory" ||
      location.pathname === "/inventoryLogs"
    ) {
      setActiveState("twelveth");
    } else if (
      location.pathname === "/voidList" ||
      location.pathname === "/addVoid" ||
      location.pathname.includes("/void/")
    ) {
      setActiveState("thirteenth");
    } else {
      setActiveState("first");
    }
  }, []);
  useEffect(() => {
    if (user && user.can_perform_void) {
      setVoidUser(true);
    }
  }, [user]);
  // useEffect(() => {
  //   if (productId !== null && location.pathname === `/product/${productId}`) {
  //     console.log("second");
  //     setActiveState("second");
  //   }
  // }, [productId]);
  //console.log(activeState);

  // useEffect(() => {
  //   if (localStorage.getItem("menuState") === "true") {
  //     setSideMenuState(true);
  //   } else {
  //     setSideMenuState(false);
  //   }
  // }, [localStorage.getItem("menuState"), props]);
  const detectLocation = (pathname) => {
    if (location.pathname === pathname) {
      return "active";
    } else {
      return "";
    }
  };
  const handleChnageSidebar = () => {
    if (toggleClick === true) {
      dispatch(SidebarAction(false));
    } else {
      dispatch(SidebarAction(true));
    }
  };
  //console.log(props);
  return (
    <div className={toggleClick === true ? "sidebar d-none" : "sidebar"}>
      <div className="d-flex justify-content-end m-2 d-md-none">
        <button
          type="button"
          className="btn-close"
          onClick={handleChnageSidebar}
        ></button>
      </div>
      <div className="mt-5 mb-5 d-flex justify-content-center">
        <img
          src={logo}
          alt="logo"
          style={{ maxHeight: "5.3vh", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </div>
      {activeState !== undefined ? (
        <Accordion className="me-2 ms-2" defaultActiveKey={activeState}>
          <Accordion.Item className="mb-1 manual-body" eventKey="first">
            <Link to="/" className={detectLocation("/")}>
              <img
                src={dashboardLogo}
                alt="dashboard"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Dashboard
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="second">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={productLogo}
                alt="productLogo"
              />{" "}
              Products{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/productList"
                className={detectLocation("/productList")}
              >
                {detectLocation("/productList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}{" "}
                Product List
              </Link>
              <Link
                to="/addProducts"
                className={detectLocation("/addProducts")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addProducts") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}{" "}
                Add Product
              </Link>
              <Link
                to="/category"
                className={detectLocation("/category")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/category") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}{" "}
                Category
              </Link>
              <Link
                to="/subCategory"
                className={detectLocation("/subCategory")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/subCategory") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}{" "}
                Sub Category
              </Link>
              <Link
                to="/genericList"
                className={detectLocation("/genericList")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/genericList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}{" "}
                Generic Name
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="third">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={supplierLogo}
                alt="supplierLogo"
              />
              Supplier{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/supplierList"
                className={detectLocation("/supplierList")}
              >
                {detectLocation("/supplierList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Supplier List
              </Link>
              <Link
                to="/addSupplier"
                className={detectLocation("/addSupplier")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addSupplier") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Supplier
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="fourth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={vendorLogo}
                alt="vendorLogo"
              />
              Vendor{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/vendorList" className={detectLocation("/vendorList")}>
                {detectLocation("/vendorList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Vendor List
              </Link>
              <Link
                to="/addVendor"
                className={detectLocation("/addVendor")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addVendor") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Vendor
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="fifth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={purchaseLogo}
                alt="purchaseLogo"
              />
              Purchase{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/purchaseList"
                className={detectLocation("/purchaseList")}
              >
                {detectLocation("/purchaseList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Purchase List
              </Link>
              <Link
                to="/addPurchase"
                className={detectLocation("/addPurchase")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addPurchase") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Prod. Rcv. (GRN)
              </Link>
              <Link
                to="/requestPurchase"
                className={detectLocation("/requestPurchase")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/requestPurchase") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Purchase Req. List
              </Link>
              <Link
                to="/addRequestPurchase"
                className={detectLocation("/addRequestPurchase")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addRequestPurchase") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Purchase Req.
              </Link>
              <Link
                to="/purchaseOrderList"
                className={detectLocation("/purchaseOrderList")}
                style={{ paddingTop: "20px", fontSize: "19px" }}
              >
                {detectLocation("/purchaseOrderList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Purchase Order List
              </Link>
              <Link
                to="/addPurchaseOrder"
                className={detectLocation("/addPurchaseOrder")}
                style={{ paddingTop: "20px", fontSize: "19px" }}
              >
                {detectLocation("/addPurchaseOrder") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Purchase Order
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="sixth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={returnLogo}
                alt="returnLogo"
              />
              Return{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/returnList" className={detectLocation("/returnList")}>
                {detectLocation("/returnList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Return List
              </Link>
              <Link
                to="/addReturn"
                className={detectLocation("/addReturn")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addReturn") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Return
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="ninth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={warehouseLogo}
                alt="warehouseLogo"
              />
              Destination{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/destinationList"
                className={detectLocation("/destinationList")}
              >
                {detectLocation("/destinationList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Destination List
              </Link>
              <Link
                to="/addDestination"
                className={detectLocation("/addDestination")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addDestination") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Destination
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="tenth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={transferLogo}
                alt="transferLogo"
              />
              Transfer{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/transferList"
                className={detectLocation("/transferList")}
              >
                {detectLocation("/transferList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Transfer List
              </Link>
              <Link
                to="/addTransfer"
                className={detectLocation("/addTransfer")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/addTransfer") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Transfer
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mb-1 manual-body" eventKey="eleventh">
            <Link to="/due-details" className={detectLocation("/due-details")}>
              <img
                src={dueLogo}
                alt="dueLogo"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Due Details
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="thirteenth">
            <Accordion.Header>
              <img className="accordion-img" src={voidLogo} alt="voidLogo" />
              Void{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/voidList" className={detectLocation("/voidList")}>
                {detectLocation("/voidList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Void List
              </Link>
              {voidUser ? (
                <Link
                  to="/addVoid"
                  className={detectLocation("/addVoid")}
                  style={{ paddingTop: "16px" }}
                >
                  {detectLocation("/addVoid") === "active" ? (
                    <img
                      className="list-img"
                      src={selectedLogo}
                      alt="selectedLogo"
                    />
                  ) : (
                    <img className="list-img" src={listLogo} alt="listLogo" />
                  )}
                  Add Void
                </Link>
              ) : (
                <></>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="twelveth">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={utilitiesLogo}
                alt="utilitiesLogo"
              />
              Utilities{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/productBiography"
                className={detectLocation("/productBiography")}
              >
                {detectLocation("/productBiography") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Product Biography
              </Link>
              <Link
                to="/createInventory"
                className={detectLocation("/createInventory")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/createInventory") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Create Inventory
              </Link>
              <Link
                to="/physicalInventory"
                className={detectLocation("/physicalInventory")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/physicalInventory") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Physical Inventory
              </Link>
              <Link
                to="/inventoryLogs"
                className={detectLocation("/inventoryLogs")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/inventoryLogs") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Inventory Logs
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="seventh">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={reportsLogo}
                alt="reportsLogo"
              />
              Reports{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link
                to="/stockReports"
                className={detectLocation("/stockReports")}
              >
                {detectLocation("/stockReports") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Stock Report
              </Link>
              <Link
                to="/stockAnalysis"
                className={detectLocation("/stockAnalysis")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/stockAnalysis") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Stock Analysis
              </Link>
              <Link
                to="/returnReports"
                className={detectLocation("/returnReports")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/returnReports") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Return Report
              </Link>
              <Link
                to="/supplierRcv"
                className={detectLocation("/supplierRcv")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/supplierRcv") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Supplier Wise Rcv
              </Link>
              <Link
                to="/vendorRcv"
                className={detectLocation("/vendorRcv")}
                style={{ paddingTop: "16px" }}
              >
                {detectLocation("/vendorRcv") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Vendor Wise Rcv
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mb-1 manual-body" eventKey="eight">
            <a href="https://pos.care-box.com/" target="_blank">
              <img
                src={posLogo}
                alt="posLogo"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              POS System
            </a>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SideBar;
