import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import "../Products/Product.css";
import {
  getWarehouseList,
  getSearchWarehouseList,
} from "../../Redux/actions/warehouseActions";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";

const WarehouseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [exportData, setExportData] = useState(false);

  const { loading, warehouseList, error } = useSelector(
    (state) => state.warehouseList
  );

  const { searchLoading, searchWarehouse, searchError } = useSelector(
    (state) => state.searchWarehouseList
  );

  useEffect(() => {
    dispatch(getWarehouseList(limit, offset));
  }, [dispatch, offset]);
  useEffect(() => {
    if (warehouseList?.data) {
      setTotalItems(warehouseList.data.count);
    }
  }, [warehouseList]);
  //console.log(warehouseList);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(getSearchWarehouseList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  const handleDeleteWarehouse = (item) => {
    setSelectedItem(item);
    setDeleteModalShow(true);
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Destination",
              [
                "Destination ID",
                "Destination Name",
                "Destination Type",
                "Contact No.",
                "Email Address",
                "City",
                "Address",
                "Status",
              ],
              "Care-Box Destination List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Destination ID"] = each.id;
              obj["Destination Name"] = each.name;
              obj["Destination Type"] = each.warehouse_status;
              obj["Contact No."] = each.contact_number;
              obj["Email Address"] = each.email;
              obj["City"] = each.city;
              obj["Address"] = each.address;
              obj["Status"] = each.active_status;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Destination_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };

  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        action={() => setShowSearchResult(false)}
        destination={selectedItem}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Destination List</h4>
              <p className="product-paragraph">
                {" "}
                Destination <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Destination List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addDestination")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Add Destination
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table bordered responsive hover>
              <thead className="text-center table-header">
                <tr>
                  {/* <th>
                    <Form.Check type="checkbox" id="checkbox" />
                  </th> */}
                  <th>SL No.</th>
                  <th>Warehouse/Store Name</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>City</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchWarehouse?.data !== undefined &&
                searchWarehouse?.data.results.length !== 0 ? (
                  searchWarehouse?.data.results.map((warehouse) => (
                    <tr key={warehouse.id}>
                      {/* <td>
                        <Form.Check type="checkbox" id="checkbox" />
                      </td> */}
                      <td>{warehouse.id}</td>
                      <td>{warehouse.name}</td>
                      <td>{warehouse.contact_number}</td>
                      <td>{warehouse.email}</td>
                      <td>{warehouse.city}</td>
                      <td>{warehouse.address?.substring(0, 15)}</td>
                      <td>{warehouse.active_status}</td>
                      <td>{warehouse.warehouse_status}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() =>
                            navigate(`/destination/${warehouse.id}`)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faPenAlt}
                          color="#637381"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() =>
                            navigate(`/updateDestination/${warehouse.id}`)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#E40000"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() => handleDeleteWarehouse(warehouse)}
                        />
                      </td>
                    </tr>
                  ))
                ) : !showSearchResult &&
                  warehouseList?.data !== undefined &&
                  warehouseList?.data.results.length !== 0 ? (
                  warehouseList?.data.results.map((warehouse) => (
                    <tr key={warehouse.id}>
                      {/* <td>
                        <Form.Check type="checkbox" id="checkbox" />
                      </td> */}
                      <td>{warehouse.id}</td>
                      <td>{warehouse.name}</td>
                      <td>{warehouse.contact_number}</td>
                      <td>{warehouse.email}</td>
                      <td>{warehouse.city}</td>
                      <td>{warehouse.address?.substring(0, 15)}</td>
                      <td>{warehouse.active_status}</td>
                      <td>{warehouse.warehouse_status}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() =>
                            navigate(`/destination/${warehouse.id}`)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faPenAlt}
                          color="#637381"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() =>
                            navigate(`/updateDestination/${warehouse.id}`)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#E40000"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() => handleDeleteWarehouse(warehouse)}
                        />
                      </td>
                    </tr>
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WarehouseList;
