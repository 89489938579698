import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faChevronRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "./transfer.css";
import { searchProductList } from "../../Redux/actions/productActions";
import useLocalStorage from "../../hooks/useLocalStorage";
import { getWarehouseList } from "../../Redux/actions/warehouseActions";
import {
  createTransfer,
  getSearchPurchaseList,
  searchPurchaseProductList,
} from "../../Redux/actions/transferActions";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import { getBatchDetails } from "../../Redux/actions/returnActions";
import AddTransferModal from "../PopupModal/AddTransferModal/AddTransferModal";

const AddTransfer = () => {
  const dispatch = useDispatch();

  const { searchLoading, searchProducts } = useSelector(
    (state) => state.searchProductList
  );

  const { loading, warehouseList, error } = useSelector(
    (state) => state.warehouseList
  );

  const { createLoading, message, createError } = useSelector(
    (state) => state.createTransfer
  );

  const batchDetail = useSelector((state) => state.batchDetailsList);

  const { purchaseLoading, searchPurchaseTransfer, purchaseError } =
    useSelector((state) => state.searchTransferPurchaseList);

  const { productLoading, searchPurchaseProducts, productError } = useSelector(
    (state) => state.searchPurchaseProductList
  );

  const [searchText, setSearchText] = useState("");
  const [searchPurchaseId, setSearchPurchaseId] = useState("");
  const [transferList, setTransferList] = useLocalStorage("transferList", []);
  const [selectProduct, setSelectProduct] = useState({});
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [showPurchaseSearchResult, setShowPurchaseSearchResult] =
    useState(false);
  const [selectPurchaseItems, setSelectPurchaseItems] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState("");
  const [date, setDate] = useState("");
  const [transferFrom, setTransferFrom] = useState("");
  const [transferTo, setTransferTo] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [transferNote, setTransferNote] = useState("");
  const [staffRemarks, setStaffRemarks] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  const [batchName, setBatchName] = useState("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [searchApiCall , setSearchApiCall] = useState(true);

  const [transferType, setTransferType] = useLocalStorage(
    "transferType",
    "product_wise_transfer"
  );
  //const [transferQty, setTransferQty] = useState(0);

  // Get the current date
  const currentDate = new Date();
  // Format the current date as YYYY-MM-DD
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getWarehouseList(500, 0));
    // Set the current date as the default value
    setDate(formattedDate);
  }, []);

  useEffect(() => {
    if (alertMessage && message === undefined && createError !== undefined) {
      //console.log(createError);
      setAlertMessage(false);
      if (createError.message) {
        alert(createError.message);
      } else if (createError.Warning) {
        alert(createError.Warning);
      } else if (createError.staff_remark) {
        alert(`Staff Remarks: ${createError.staff_remark}`);
      } else if (createError.transfer_total) {
        alert(`Transfer Total: ${createError.transfer_total}`);
      } else if (createError.error) {
        alert(createError.error);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      setTransferList([]);
      setDate(formattedDate);
      setTransferFrom("");
      setTransferTo("");
      setShippingCost(0);
      setTransferNote("");
      setStaffRemarks("");
      SuccessAlert("New Transfer Created Successfully");
      setAlertMessage(false);
      setSelectedPurchaseId("");
    }
  }, [alertMessage, message, createError]);

  // Hasan involvement
  // const handleSearchResult = (e) => {
  //   //console.log(e.target.value);
  //   if (e.target.value.length > 1) {
  //     //console.log("inside");
  //     setShowSearchResult(true);
  //     dispatch(searchProductList(e.target.value));
  //   } else {
  //     setShowSearchResult(false);
  //   }
  // };


  useEffect(() =>{
    if(searchProducts?.data?.waiting){
      setSearchApiCall(false);
    }else{
      setSearchApiCall(true);
    }
  },[searchProducts])

  const doSearch = () => {
    if (searchText && searchText.length > 1 && searchApiCall) {
      // console.log("doSearch");
      setShowSearchResult(true);
      dispatch(searchProductList(searchText));
    }
  };

  useEffect(() => {
    if (searchText === "") {
      setShowSearchResult(false);
    }
  }, [searchText]);

  useEffect(() => {
    const debounceDelay = 500; // Adjust this delay as needed

    const debounceHandler = setTimeout(() => {
      doSearch();
    }, debounceDelay);

    return () => {
      // Cleanup the previous timeout when the input value changes
      clearTimeout(debounceHandler);
    };
  }, [searchText]);

  // end of Hasan involvement

  const transferListEachId = transferList.map((each) => each.id);
  const handleSelectProduct = (product) => {
    //setSelectProduct(product);
    //setShowSearchResult(false);
    if (batchName === "") {
      alert("Select Batch Name of Product");
    } else if (transferListEachId.includes(selectProduct.id)) {
      alert("Product Already Added");
    } else {
      setTransferList([
        ...transferList,
        {
          id: product.id,
          productName: product.product_name,
          unitSize: product.unit_size,
          batch_name: batchName,
          barcode: product.barcode,
          totalStock: product.stocks,
          destinationStock: product.wh_stock,
          retailStock: product.retail_stock,
          transfer_quantity: 0,
          salesPrice:
            product.new_sale_price && product.new_sale_price !== 0
              ? product.new_sale_price
              : product.sale_price,
          vat: product.sale_vat ? product.sale_vat : 0,
        },
      ]);
      setBatchName("");
      setSelectProduct({});
    }
  };

  const updateTransferQty = (event, id) => {
    setTransferList(
      transferList.map((product) =>
        product.id === id
          ? {
              ...product,
              transfer_quantity:
                event.target.value.length === 0
                  ? 0
                  : event.target.value >= 0
                  ? parseInt(event.target.value)
                  : product.transfer_quantity,
            }
          : product
      )
    );
  };

  const removeProduct = (id) => {
    setTransferList(
      transferList.filter((product) => {
        return product.id !== id;
      })
    );
    alert(`Removed Product ID ${id}`);
  };

  const totalAmount = transferList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.salesPrice * (product.vat / 100) + product.salesPrice) *
        product.transfer_quantity,
    0
  );

  const submitTransfer = () => {
    if (
      transferList.length !== 0 &&
      transferFrom !== "" &&
      transferTo !== "" &&
      date !== "" &&
      transferNote !== ""
    ) {
      const body = {
        date: date,
        transfer_from: transferFrom,
        transfer_to: transferTo,
        transfer_type:
          transferType === "product_wise_transfer" ? "single" : "purchase",
        purchase:
          transferType === "product_wise_transfer"
            ? ""
            : transferList[0].purchaseId,
        transfer_product: transferList.map((product) => ({
          id: product.id,
          transfer_quantity: product.transfer_quantity,
          batch_name: product.batch_name,
        })),
        shipping_cost: parseInt(shippingCost),
        transfer_note: transferNote,
        staff_remark: staffRemarks,
        transfer_total:
          parseFloat(totalAmount.toFixed(2)) + parseInt(shippingCost),
      };
      dispatch(createTransfer(body));
      setAlertMessage(true);
      //console.log(body);
    } else if (
      transferList.length !== 0 &&
      transferFrom !== "" &&
      transferTo !== "" &&
      date !== ""
    ) {
      const body = {
        date: date,
        transfer_from: transferFrom,
        transfer_to: transferTo,
        transfer_type:
          transferType === "product_wise_transfer" ? "single" : "purchase",
        purchase:
          transferType === "product_wise_transfer"
            ? ""
            : transferList[0].purchaseId,
        transfer_product: transferList.map((product) => ({
          id: product.id,
          transfer_quantity: product.transfer_quantity,
          batch_name: product.batch_name,
        })),
        shipping_cost: parseInt(shippingCost),
        staff_remark: staffRemarks,
        transfer_total:
          parseFloat(totalAmount.toFixed(2)) + parseInt(shippingCost),
      };
      dispatch(createTransfer(body));
      setAlertMessage(true);
      //console.log(body);
    } else if (transferList.length === 0) {
      alert("Add Product for Transfer");
    } else if (transferFrom === "" || transferTo === "") {
      alert("Select Warehouse/store for Transfer");
    } else if (date === "") {
      alert("Select Date");
    }
  };
  const transferProduct = transferList.map((product) => ({
    id: product.id,
    transfer_quantity: product.transfer_quantity,
  }));
  //console.log(batchDetail);

  // My Custom Code (Hasan)
  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const containerRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);

  useEffect(() => {
    setSuggestionSelect(null);
  }, [searchProducts]);

  useEffect(() => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined && searchProducts?.data.results &&
      searchProducts?.data.results.length !== 0
    ) {
      // console.log("containerRef = ", containerRef.current);
      const childDivs = Array.from(containerRef.current.children);
      // console.log('childDivs = ', childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      // console.log('offsets', offsets);
      setChildOffsets(offsets);
    }
  }, [searchProducts, showSearchResult]);

  const searchInputSuggestionHendler = (e) => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results?.length
    ) {
      if (e.key === "ArrowUp") {
        // console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        // console.log("searchProducts = ",searchProducts.data.results);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          // console.log("searchProducts = ",searchProducts.data.results);

          containerRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        }
      }
      if (e.key === "ArrowDown") {
        if (suggestionSelect || suggestionSelect === 0) {
          if (searchProducts.data.results.length > suggestionSelect + 1) {
            setSuggestionSelect((previousState) => previousState + 1);
            // console.log("searchProducts = ",searchProducts.data.results);
            containerRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          setSuggestionSelect(0);
          // console.log("searchProducts = ",searchProducts.data.results);
        }
      }
      if (e.key === "Enter") {
        if (suggestionSelect || suggestionSelect === 0) {
          // console.log("Dispatch your action");
          setSelectProduct(searchProducts.data.results[suggestionSelect]);
          setShowSearchResult(false);
          setSuggestionSelect(null);
          dispatch(
            getBatchDetails(searchProducts.data.results[suggestionSelect].id)
          );
        }
      }
    }
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 3) {
      //console.log("inside");
      setShowPurchaseSearchResult(true);
      //dispatch(searchProductList(e.target.value));
      setTimeout(() => {
        setSearchPurchaseId(e.target.value);
        // console.log("timeout");
      }, 500);
      //debounce(dispatch(searchProductList(e.target.value)), 500);
    } else {
      setShowPurchaseSearchResult(false);
    }
  };
  useEffect(() => {
    if (searchPurchaseId.length > 3) {
      //console.log("debounce");
      //debounce(dispatch(searchProductList(searchValue)), 5000);
      dispatch(getSearchPurchaseList(searchPurchaseId));
    }
  }, [searchPurchaseId]);

  useEffect(() => {
    if (
      selectPurchaseItems &&
      !productLoading &&
      searchPurchaseProducts.data &&
      searchPurchaseProducts.data.purchase_product &&
      searchPurchaseProducts.data.purchase_product.length
    ) {
      setTransferList(
        searchPurchaseProducts.data.purchase_product.map((product) => ({
          pop_id: product.purchase_id,
          purchaseId: product.purchase,
          id: product.product_id,
          productName: product.product_name,
          unitSize: product.product_unit,
          batch_name: product.purchase_batch,
          barcode: product.barcode,
          totalStock: product.stock,
          destinationStock: product.warehouse_stock,
          retailStock: product.retail_stock,
          transfer_quantity: product.batch_stock,
          salesPrice:
            product.new_sales_price && product.new_sales_price !== 0
              ? product.new_sales_price
              : product.sales_price,
          vat: product.vat ? product.vat : 0,
        }))
      );
      setSelectPurchaseItems(false);
    }
  }, [searchPurchaseProducts, selectPurchaseItems, productLoading]);

  //console.log(selectedPurchaseId);

  //End of My Custom Code (Hasan)

  return (
    <div>
      <AddTransferModal
        show={previewOpen}
        onHide={() => setPreviewOpen(false)}
        transferList={transferList}
        productLoading={productLoading}
        transferFrom={transferFrom}
        transferTo={transferTo}
        totalAmount={totalAmount.toFixed(2)}
        shippingCost={shippingCost}
        netReturnValue={
          parseFloat(totalAmount.toFixed(2)) + parseInt(shippingCost)
        }
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Transfer Add</h4>
          <p className="product-paragraph container-view">
            {" "}
            Transfer <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Transfer
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row>
              <Col lg={8}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="transferType"
                >
                  <Form.Label>
                    Select Transfer Type
                    <span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={transferType}
                    onChange={(e) => {
                      setTransferType(e.target.value);
                      setTransferList([]);
                      setSelectedPurchaseId("");
                    }}
                  >
                    <option value="">Select Transfer Type</option>
                    <option value="product_wise_transfer">
                      Single Product Wise Transfer
                    </option>
                    <option value="purchase_wise_transfer">
                      Purchase Wise Transfer
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Date<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="11-05-23"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    //value={props.supplierId}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    From Warehouse/Store
                    <span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={transferFrom}
                    onChange={(e) => setTransferFrom(e.target.value)}
                    // required
                  >
                    <option value="">Select Warehouse/Store</option>
                    {warehouseList.data?.results.map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    To Warehouse/Store<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={transferTo}
                    onChange={(e) => setTransferTo(e.target.value)}
                    // required
                  >
                    <option value="">Select Warehouse/Store</option>
                    {warehouseList.data?.results.map((warehouse, index) => (
                      <option key={index} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {transferType === "product_wise_transfer" ? (
                <Col lg={8} style={{ position: "relative" }}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search Product</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search By Product Name or Id"
                      // onChange={handleSearchResult}

                      onChange={(e) => setSearchText(e.target.value)}
                      autoComplete="off"
                      onKeyDown={(e) => searchInputSuggestionHendler(e)}
                    />
                    {showSearchResult ? (
                      !searchLoading &&
                      searchProducts?.data !== undefined &&
                      searchProducts?.data.results?.length ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          {searchProducts?.data.results.map((product, i) => (
                            <div
                              className={`card shadow m-2 rounded border border-0 p-2 ${
                                suggestionSelect !== null &&
                                suggestionSelect === i
                                  ? "selectedSearchItemHover"
                                  : ""
                              }`}
                              key={i}
                              onClick={() => {
                                setSelectProduct(product);
                                setShowSearchResult(false);
                                dispatch(getBatchDetails(product.id));
                                //handleSelectProduct(product);
                              }}
                            >
                              <div>
                                <div className="d-flex align-content-center">
                                  <div className="">
                                    {product.product_name} - {product.generic} (
                                    {product.unit_size}) - (
                                    {product.sub_catagory_1})
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : !searchLoading &&
                        searchProducts?.data !== undefined &&
                        searchProducts?.data.results?.length === 0 ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>No Product Found</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2 ">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
              ) : (
                <Col lg={8} style={{ position: "relative" }}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search Purchase/GRN ID: </Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search By Purchase/GRN ID"
                      // onChange={handleSearchResult}
                      onChange={handleSearchResult}
                      autoComplete="off"
                    />
                    {showPurchaseSearchResult ? (
                      !purchaseLoading &&
                      searchPurchaseTransfer?.data !== undefined &&
                      searchPurchaseTransfer.data.results.length !== 0 ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          style={{ zIndex: "2" }}
                        >
                          {searchPurchaseTransfer.data.results.map(
                            (purchase, i) => (
                              <div
                                className={`card shadow m-2 rounded border border-0 p-2`}
                                key={purchase.id}
                                onClick={() => {
                                  setShowPurchaseSearchResult(false);
                                  setTransferList([]);
                                  setSelectPurchaseItems(true);
                                  dispatch(
                                    searchPurchaseProductList(purchase.id)
                                  );
                                  setSelectedPurchaseId(purchase.id);
                                  //handleSelectProduct(product);
                                }}
                              >
                                <div>
                                  <div className="d-flex align-content-center">
                                    <div className="">
                                      {purchase.purchase_id}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : !searchLoading &&
                        searchPurchaseTransfer?.data !== undefined &&
                        searchPurchaseTransfer.data.results.length === 0 ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>No Purchase/GRN Id Found</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2 ">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
              )}
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Shipping Cost</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Shipping Cost"
                    value={shippingCost}
                    onChange={(e) =>
                      e.target.value.length === 0
                        ? setShippingCost(0)
                        : e.target.value >= 0
                        ? setShippingCost(e.target.value)
                        : setShippingCost(0)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            {selectProduct.product_name !== undefined ? (
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Product Name
                      <span className="required-field"></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product name"
                      value={selectProduct.product_name}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Select Batch
                      <span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={batchName}
                      onChange={(e) => setBatchName(e.target.value)}
                    >
                      <option value="">Choose Batch Name</option>
                      {batchDetail.batchList?.batch?.map((batch) => (
                        <option key={batch} value={batch}>
                          {batch}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Button
                    className="add-product-btn border-0"
                    onClick={() => handleSelectProduct(selectProduct)}
                  >
                    Add Product
                  </Button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {transferList.length &&
            transferType === "purchase_wise_transfer" ? (
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="p-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    background: "#dce0dd",
                    borderRadius: "5px",
                  }}
                >
                  Selected Purchase/GRN ID: {transferList[0].pop_id}
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className="mt-3 transfer-table">
              <Table responsive hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>SL</th>
                    <th>Barcode</th>
                    <th>Product Information</th>
                    <th>Batch No.</th>
                    <th>Total Stock</th>
                    <th>Warehouse Stock</th>
                    <th>Retail Stock</th>
                    <th>Transfer Qty</th>
                    <th>MRP</th>
                    <th>Vat in %</th>
                    <th>Subtotal</th>
                    {transferType !== "purchase_wise_transfer" && (
                      <th>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {transferList.length > 0 && !productLoading ? (
                    transferList.map((product, i) => (
                      <tr key={product.id}>
                        <td>{i + 1}</td>
                        <td>{product.barcode}</td>
                        <td>
                          {product.productName} {product.unitSize}
                        </td>
                        <td>{product.batch_name}</td>
                        <td>{product.totalStock}</td>
                        <td>{product.destinationStock}</td>
                        <td>{product.retailStock}</td>
                        <td>
                          <input
                            className="table-data-input-field"
                            type="number"
                            min={0}
                            max={product.totalStock}
                            value={product.transfer_quantity}
                            onChange={(event) => {
                              updateTransferQty(event, product.id);
                            }}
                          />
                        </td>
                        <td>{product.salesPrice}</td>
                        <td>{product.vat}%</td>
                        <td>
                          {(
                            (product.salesPrice * (product.vat / 100) +
                              product.salesPrice) *
                            product.transfer_quantity
                          ).toFixed(2)}
                        </td>
                        {transferType !== "purchase_wise_transfer" && (
                          <td>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color="#E40000"
                              style={{
                                paddingLeft: "0.405rem",
                                cursor: "pointer",
                              }}
                              onClick={() => removeProduct(product.id)}
                            />
                          </td>
                        )}
                      </tr>
                    ))
                  ) : productLoading ? (
                    <tr>
                      <td colSpan="12">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="12">No Product Added</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="p-3">
                <Row className="">
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Total Amount </b> : {totalAmount.toFixed(2)} ৳
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Shipping Cost </b> : {shippingCost} ৳
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Grand Total </b> :{" "}
                      {parseFloat(totalAmount.toFixed(2)) +
                        parseInt(shippingCost)}{" "}
                      ৳
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
            <Row className="mt-4 mb-2">
              <Col lg={6}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Transfer Note (If Any) <span></span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Transfer Note"
                    style={{ minHeight: "100px", maxHeight: "120px" }}
                    value={transferNote}
                    onChange={(e) => setTransferNote(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Staff Remarks<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Staff Remarks"
                    style={{ minHeight: "100px", maxHeight: "120px" }}
                    value={staffRemarks}
                    onChange={(e) => setStaffRemarks(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {createLoading === true ? (
              <Row className="mt-2">
                <Col xl={4} lg={12} className="d-flex justify-content-center">
                  <Spinner animation="border" variant="warning" />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mt-3">
              <Col xl={2} lg={4} className="mb-2">
                {createLoading === true ? (
                  <Button
                    className="disable-submit-btn"
                    variant="secondary"
                    disabled
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    className="submit-btn border-0"
                    onClick={submitTransfer}
                  >
                    Submit
                  </Button>
                )}
              </Col>

              <Col xl={2} lg={4} className="mb-2">
                <Button
                  className="preview-btn border-0"
                  onClick={() => setPreviewOpen(true)}
                >
                  Preview
                </Button>
              </Col>

              <Col xl={2} lg={4}>
                <Button
                  className="cancel-btn border-0"
                  //onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddTransfer;
