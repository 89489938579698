import React from "react";

function VoidReasonComment({
  voidReasonOption,
  voidReasonDescription,
  setVoidReasonDescription,
}) {
  // let isChecked = false;

  // if (voidReasonOption.length) {
  //   let result = voidReasonOption.find((item) => item.id === 8);
  //   if (result) {
  //     isChecked = result;
  //   }
  // }

  // if (!isChecked) {
  //   if (voidReasonDescription) {
  //     setVoidReasonDescription("");
  //   }
  // }

  return (
    <textarea
      className="void-reasons-comment"
      placeholder="Write Reasons"
      value={voidReasonDescription}
      onChange={(e) => setVoidReasonDescription(e.target.value)}
      // disabled={isChecked ? false : true}
    ></textarea>
  );
}

export default VoidReasonComment;
