import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "./transfer.css";
import { getTransferDetails } from "../../Redux/actions/transferActions";
import SingleTransferPdf from "../../Services/SingleTransferPdf";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const EachTransfer = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, transfer, error } = useSelector(
    (state) => state.transferDetail
  );
  useEffect(() => {
    dispatch(getTransferDetails(param.id));
  }, [dispatch, param.id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(transfer);
  return (
    <div>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Transfer List Details
          </h4>
          <p className="product-paragraph container-view">
            Full Details of Transfer List
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => SingleTransferPdf(transfer)}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img
                  className="d-none pe-3"
                  src={excelIcon}
                  alt="pdf"
                  height={45}
                />
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Transfer Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="11-05-23"
                    value={
                      transfer.transfer_type === "purchase"
                        ? "Purchase Wise Transfer"
                        : "Product Wise Transfer"
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formTransferBy"
                >
                  <Form.Label>Transferred By</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Transferred By"
                    value={transfer.issued_by ? transfer.issued_by.Name : ""}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="11-05-23"
                    value={transfer.date ? transfer.date : ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    From {transfer.transfer_from?.warehouse_status}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Care-box"
                    value={
                      transfer.transfer_from ? transfer.transfer_from.name : ""
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    To {transfer.transfer_to?.warehouse_status}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Store"
                    value={
                      transfer.transfer_to ? transfer.transfer_to.name : ""
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Shipping</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="0"
                    value={transfer.shipping_cost ? transfer.shipping_cost : ""}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="transfer-table">
              <Table responsive hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>Barcode</th>
                    <th>Product Information</th>
                    <th>Batch No.</th>
                    <th>Total Stock</th>
                    <th>Warehouse Stock</th>
                    <th>Retail Stock</th>
                    <th>Transfer Qty</th>
                    <th>MRP</th>
                    <th>Vat s(%)</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {transfer.transfer_product &&
                  transfer.transfer_product.length &&
                  !loading ? (
                    transfer.transfer_product.map((transfer) => (
                      <tr key={transfer.id}>
                        <td>
                          {transfer.product.barcode
                            ? transfer.product.barcode
                            : "N/A"}
                        </td>
                        <td>
                          {transfer.product.product_name}{" "}
                          {transfer.product.unit}
                        </td>
                        <td>
                          {transfer.product_batch
                            ? transfer.product_batch
                            : "N/A"}
                        </td>
                        <td>{transfer.product.stock}</td>
                        <td>{transfer.product.wh_stock} </td>
                        <td>{transfer.product.retail_stock} </td>
                        <td>{transfer.transfer_quantity} </td>
                        <td>
                          {transfer.product.new_product_price === "0.00"
                            ? transfer.product.product_price
                            : transfer.product.new_product_price}
                        </td>
                        <td>
                          {transfer.product.VAT
                            ? parseInt(transfer.product.VAT)
                            : "0"}
                          %
                        </td>
                        <td>
                          {transfer.product.new_product_price === "0.00"
                            ? (
                                (transfer.product.product_price *
                                  (parseInt(
                                    transfer.product.VAT
                                      ? transfer.product.VAT
                                      : 0
                                  ) /
                                    100) +
                                  parseFloat(transfer.product.product_price)) *
                                transfer.transfer_quantity
                              ).toFixed(2)
                            : (
                                (transfer.product.new_product_price *
                                  (parseInt(
                                    transfer.product.VAT
                                      ? transfer.product.VAT
                                      : 0
                                  ) /
                                    100) +
                                  parseFloat(
                                    transfer.product.new_product_price
                                  )) *
                                transfer.transfer_quantity
                              ).toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ) : transfer.transfer_product &&
                    transfer.transfer_product.length === 0 &&
                    !loading ? (
                    <tr>
                      <td colSpan="10">No Product Found</td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="10">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="p-3">
                <Row className="">
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Total Amount </b> :{" "}
                      {(
                        parseFloat(transfer.transfer_total) -
                        transfer.shipping_cost
                      ).toFixed(2)}{" "}
                      ৳
                    </p>
                    {/* <div className="d-flex justify-content-between">
                      <p>
                        <b>Total Amount </b> :
                      </p>
                      <p className="ps-2">
                        {(
                          parseInt(transfer.transfer_total) -
                          transfer.shipping_cost
                        ).toFixed(2)}{" "}
                        ৳
                      </p>
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Shipping Cost </b> :{" "}
                      {parseFloat(transfer.shipping_cost)?.toFixed(2)} ৳
                    </p>
                    {/* <div
                      className="d-flex justify-content-between border-top border-bottom"
                      style={{ background: "#fafbfe" }}
                    >
                      <p>
                        <b>Shipping Cost </b> :
                      </p>
                      <p className="ps-2">
                        {transfer.shipping_cost?.toFixed(2)} ৳
                      </p>
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Grand Total </b> : {transfer.transfer_total} ৳
                    </p>
                    {/* <div className="d-flex justify-content-between px-1">
                      <p>
                        <b>Grand Total </b> :
                      </p>
                      <p className="ps-2">{transfer.transfer_total} ৳</p>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </div>
            <Row className="mt-4 mb-2">
              <Col lg={6}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Transfer Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Transfer Note"
                    style={{ height: "100px" }}
                    value={transfer.transfer_note ? transfer.transfer_note : ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Staff Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Staff Remarks"
                    style={{ height: "100px" }}
                    value={transfer.staff_remark ? transfer.staff_remark : ""}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EachTransfer;
